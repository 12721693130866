<template>
  <b-container>
    <div style="width: 100%" id="c24pp-power-iframe"></div>
  </b-container>
</template>

<script>
import $ from "jquery";

export default {
  created() {
    $(document).ready(function() {
      let script = document.createElement("script");
      script.setAttribute(
        "src",
        "https://files.check24.net/widgets/auto/80229/c24pp-power-iframe/power-iframe.js"
      );
      document.head.appendChild(script);
    });
  },
};
</script>
